<template>
  <div id="SeckillList">
    <div class="live_body">
      <!-- 顶部导航 -->
      <div class="search_top  flex-c-s-s">
        <van-icon name="arrow-left" size="0.5rem" color="#ffffff" style="margin-left:0.3rem;margin-top:0.6rem"
                  @click="backPath"
        />
        <!-- <div class="ser_search  flex-r-sb-c" style="background: none;">
          <div />
          <van-icon name="arrow-left" size="0.5rem" color="#ffffff" style="margin-left:0.3rem" @click="backPath" />
          <van-search v-model="searchValue" class="classify_ser" placeholder="请输入商品名称搜索" shape="round"
                      @search="inputSer" @focus="onFocus"
          />
          <div />
        </div> -->
      </div>
    </div>
    <!-- ser -->
    <div class="recommend_saixuan flex-r-sb-c">
      <div class="search_filter flex-r-sa-c" style="width:100%;margin-top:0.1rem;padding-top:0.2rem;background:none">
        <div :class="search_filter_tab === 5?'search_filter_item flex-r-c-c search_filter_active' : 'search_filter_item flex-r-c-c'"
             @click="changeFilter(5)"
        >
          <span>全部分类</span>
          <van-image v-if="saleNum === 51" width="0.32rem" height="0.19rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/serClassfly/search_filter_2.png"
          />
          <van-image v-else width="0.32rem" height="0.19rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/serClassfly/search_filter_1.png"
          />
        </div>
        <div :class="search_filter_tab === 2?'search_filter_item flex-r-c-c search_filter_active' : 'search_filter_item flex-r-c-c'"
             @click="changeFilter(2)"
        >
          <span>销量</span>
          <van-image v-if="saleNum === 21" width="0.32rem" height="0.37rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/serClassfly/search_filter_0.png"
          />
          <van-image v-if="saleNum === 22" width="0.32rem" height="0.19rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/serClassfly/search_filter_1.png"
          />
          <van-image v-if="saleNum === 23" width="0.32rem" height="0.19rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/serClassfly/search_filter_2.png"
          />
        </div>
        <div :class="search_filter_tab === 3?'search_filter_item flex-r-c-c search_filter_active' : 'search_filter_item flex-r-c-c'"
             @click="changeFilter(3)"
        >
          <span>价格</span>
          <van-image v-if="price === 31" width="0.32rem" height="0.37rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/serClassfly/search_filter_0.png"
          />
          <van-image v-if="price === 32" width="0.32rem" height="0.19rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/serClassfly/search_filter_1.png"
          />
          <van-image v-if="price === 33" width="0.32rem" height="0.19rem"
                     src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/serClassfly/search_filter_2.png"
          />
        </div>
        <div v-if="showPopup2 " class="tab_category_popup2 flex-r-s-s">
          <div :class="tab4 === 0 ? 'popup_item popup_active':'popup_item'" @click="clickTabcategory(0)">全部</div>
          <div v-for="(category,index_category) in mallCategoryList" :key="index_category"
               :class="tab4 === category.id ? 'popup_item popup_active':'popup_item'"
               @click="clickTabcategory(category.id)"
          >{{ category.name }}</div>
        </div>
      </div>
    </div>
    <!-- list -->
    <div class="recommend_list flex-c-c-c">
      <div class="tab_list">
        <van-list :loading="loading" :finished="finished" finished-text="已经到底啦..." @load="onLoad">
          <div v-for="(pro_item,pro_index) in list" :key="pro_index" class="pro_card"
               @click="(e) => { $router.push('/product/detail?productId=' + pro_item.productId) }"
          >
            <div class="card_left">
              <van-image width="2.56rem" height="1.92rem" :src="pro_item.proIconUrl" />
            </div>
            <div class="card_right">
              <div class="pro_name overflow-ellipsis">{{ pro_item.proName }}</div>
              <div class="pro_brand_model overflow-ellipsis">
                <span class="pro_brand">{{ pro_item.proBrand }}</span> |
                <span class="pro_model">{{ pro_item.proModel }}</span>
              </div>
              <div class="pro_price_btn">
                <div class="pro_price">
                  <div class="pro_price_seckill">￥<span
                    style="font-size:0.53rem"
                  >{{ $utils.moneyDote(pro_item.proPrice)[0] }}</span>.{{ $utils.moneyDote(pro_item.proPrice)[1] }}
                  </div>
                </div>
                <div class="" style="font-size:0.45rem;color:rgb(7, 103, 171)" @click.stop="openCart(pro_item)">
                  <div>
                    <van-icon name="shopping-cart" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </van-list>
        <van-empty v-if="list.length === 0" class="custom-image"
                   image="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/home/serpro_empty.png"
                   description="推荐好物正在准备中..."
        />
      </div>
    </div>
    <!-- 底部弹框 -->
    <van-action-sheet v-model="maskShow" title="采购数量">
      <div class="mask_content flex-r-s-c">
        <van-image width="3.41rem" height="2.56rem" :src="proDetailInfo.proIconUrl" />
        <div class="mask_pro_info flex-c-s-s">
          <div class="pro_price">
            <template> ￥<span> {{ proDetailInfo.proPrice }} </span> </template>
          </div>
          <div class="pro_name van-multi-ellipsis--l2">{{ proDetailInfo.proName }}</div>
          <div class="pro_other van-multi-ellipsis--l2">{{ proDetailInfo.proBrand }} | {{ proDetailInfo.proModel }}
          </div>
        </div>
      </div>
      <div class="pro_num flex-r-sb-c">
        <div class="name">采购数量</div>
        <van-stepper v-model="value" :min="min" :max="max" :step="step" integer disable-input theme="round"
                     button-size="22" @change="onChange"
        />
      </div>
      <div class="pro_num flex-r-sb-c">
        <div class="name">总计</div>
        <div class="total_price">￥ <span style="font-size: 0.53rem;">{{ totalProPrice }}</span></div>
      </div>
      <van-divider />
      <div class="mask_pro_btn flex-r-c-c">
        <div class="right_btn flex-r-s-s">
          <div class="right_btn_cart" @click="addCart">加入购物车</div>
          <div class="right_btn_pay" @click="subPro">立即采购</div>
        </div>
      </div>
    </van-action-sheet>
    <!-- 直播播放框 -->
    <div class="live_box">
      <div id="player" style="border-radius:10px" />
      <van-image width="102px" height="0.69rem"
                 src="https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/cart/into_live2.png" @click="jumpLive"
      />
    </div>
  </div>
</template>

<script>
import './../seckill/index.scss'
import { shoppingCart_addUnion } from '@/api/cart'
import { searchMallProductRecommend, searchMallProductCategoryBrand } from '@/api/pro'
// 组件加载
export default {
  name: 'RecommendProList',
  data() {
    return {
      searchValue: '',
      // 滚动列表
      loading: false,
      finished: false,
      list: [],
      serchFrom: { pageNum: 0, pageSize: 20, keyWords: '', categoryId: '', orderType: '', orderWay: '', tagId: '327' },

      search_filter_tab: 2,
      saleNum: 21,
      issaleNum: false,
      price: 31,
      isprice: false,
      popupName: '全部商品',
      showPopup: false,
      showPopup2: false,
      showPopup3: false,
      tab4: 41,
      popupTitle: '',
      mallCategoryList: [],
      brandList: [],

      // 底部弹框
      maskShow: false,
      proDetailInfo: {},
      value: '',
      min: 1,
      max: 9999,
      step: 1,
      totalProPrice: '',
      // 直播
      player: null,
      playerJs: 'https://player.polyv.net/resp/live-h5-player/latest/liveplayer.min.js',
      uid: 'dbc6f019be',
      vid: '4124838'
    }
  },
  mounted() {
    this.loadPlayerScript(this.loadPlayer)
  },
  activated() {
    console.log('keepalive 进入liveList')
    if (sessionStorage.getItem('listJump')) {
      console.log('keepalive listJump')
      window.scrollTo({
        top: 0, // 滚动到顶部
        behavior: 'smooth' // 平滑滚动
      })
      sessionStorage.removeItem('listJump')
      this.showPopup = false; this.showPopup2 = false
      this.serchFrom.pageNum = 1; this.serchFrom.keyWords = this.searchValue; this.list = []
      this.saleNum = 21; this.price = 31; this.tab4 = 0
      this.serchFrom.categoryId = ''; this.serchFrom.orderWay = ''; this.serchFrom.orderType = ''; this.serchFrom.keyWords = ''
      this.$nextTick(() => { this.recommendList() })
    }
  },
  deactivated() {
    console.log('keepalive 离开liveList')
  },
  methods: {
    backPath() { window.history.back() },
    onFocus() {
      this.showPopup = false; this.showPopup2 = false
      this.searchValue = ''
      this.serchFrom.pageNum = 1
    },
    inputSer() {
      this.showPopup = false; this.showPopup2 = false
      this.serchFrom.pageNum = 1; this.serchFrom.keyWords = this.searchValue; this.list = []
      this.$nextTick(() => { this.recommendList() })
    },
    onLoad() {
      setTimeout(() => {
        this.serchFrom.pageNum += 1
        this.recommendList()
        this.loading = true
      }, 10)
    },
    // 列表
    recommendList() {
      searchMallProductRecommend(this.serchFrom).then(res => {
        this.list = this.list.concat(res.data.data)
        this.loading = false
        // 数据全部加载完成
        if (parseInt(res.data.data.length) === 0) { this.finished = true }
      })
    },
    changeFilter(index) {
      this.search_filter_tab = index
      if (index === 2) {
        this.isprice = false
        this.issaleNum = !this.issaleNum
        this.saleNum = this.issaleNum ? 22 : 23
        this.price = 31
        this.serchFrom.orderType = 'SALE_NUM'
        this.serchFrom.orderWay = this.issaleNum ? 'DESC' : 'ASC'
        this.serchFrom.pageNum = 1; this.list = []
        this.$nextTick(() => { this.recommendList() })
      }
      if (index === 3) {
        this.issaleNum = false
        this.isprice = !this.isprice
        this.price = this.isprice ? 32 : 33
        this.saleNum = 21
        this.serchFrom.orderType = 'SALE_PRICE'
        this.serchFrom.orderWay = this.isprice ? 'DESC' : 'ASC'
        this.serchFrom.pageNum = 1; this.list = []
        this.$nextTick(() => { this.recommendList() })
      }
      if (index === 5) {
        this.showPopup = false
        this.showPopup2 = !this.showPopup2
        searchMallProductCategoryBrand({ proName: this.searchValue, proCategoryId: '', tagId: '327', mallProductModuleId: '' }).then(res => {
          this.mallCategoryList = res.data.mallCategoryList
        })
      }
    },
    clickTabcategory(id) {
      this.tab4 = id; this.serchFrom.categoryId = id === 0 ? '' : id
      this.showPopup2 = false; this.list = []; this.serchFrom.pageNum = 1
      this.$nextTick(() => { this.recommendList() })
    },
    openCart(item) {
      this.proDetailInfo = item
      this.value = item.minQuantity
      this.maskShow = true
      if (item.quantity === null || item.quantity === undefined) {
        this.min = item.minQuantity
        this.step = item.minQuantity
        this.totalProPrice = (parseInt(item.minQuantity) * item.proPrice * 1).toFixed(2)
      } else {
        this.min = item.fashProNum
        this.totalProPrice = (parseInt(item.fashProNum) * item.proPrice * 1).toFixed(2)
      }
    },
    onChange(value) {
      clearTimeout(this.timer)
      this.timer = setTimeout(() => {
        this.value = value
        this.totalProPrice = (parseInt(value) * this.proDetailInfo.proPrice * 1).toFixed(2)
      }, 500)
    },
    addCart() {
      const parms = {
        companyId: '1000',
        products: [{ productId: this.proDetailInfo.productId, proNumber: this.value }]
      }
      console.log(parms)
      shoppingCart_addUnion(parms).then(res => {
        this.$toast({ message: '加入成功！', duration: 1 * 1000 })
        this.$utils.cartNum(this.$store)
        this.maskShow = false
      })
    },
    subPro() {
      let url = ''
      url = `/order/subOrder?item=${this.proDetailInfo.productId}-${this.value}-1000&type=2`
      this.$router.push(url)
    },
    loadPlayerScript(callback) {
      if (!window.polyvLivePlayer) {
        const myScript = document.createElement('script')
        myScript.setAttribute('src', this.playerJs)
        myScript.onload = callback
        document.body.appendChild(myScript)
        this.$nextTick(() => {
          console.log('静音')
          this.player.j2s_setVolume(0)
          setTimeout(() => {
            console.log('播放')
            this.player.j2s_resumeVideo()
          }, 1000)
        })
      } else {
        callback()
      }
    },
    loadPlayer() {
      const polyvLivePlayer = window.polyvLivePlayer
      this.player = polyvLivePlayer({
        wrap: '#player',
        width: 102,
        height: 183,
        uid: this.uid,
        vid: this.vid,
        isAutoChange: true,
        autoplay: true,
        coverImg: 'https://hanxingkeji.oss-cn-shanghai.aliyuncs.com/shop2.0/cart/fenmian.png'
      })
    },
    destroyed() {
      if (this.player) {
        this.player.destroy()
      }
    },
    jumpLive() { window.open('https://bo.xiaoyi120.com/watch/4124838') } // 跳转直播

  }
}
</script>

<style lang="scss">
.live_box {
  position: fixed;
  bottom: 2.5rem;
  right: 0.5rem;
}
</style>
